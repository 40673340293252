<template>
  <div class="originals-page">
    <Header />
    <section class="bg-color">
      <div class="scroll-down"></div>
      <div class="container p-5">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-9 text-start">
            <div class="p-5 mt-5 no-padding-x-mobile">
              <div class="d-flex align-items-center hideMobile">
                <a href="/">
                  <div class="go-back">
                    <img src="../assets/img/vec_back.svg" alt="" />
                  </div>
                </a>
                <h4 class="m-0 white">BACK</h4>
              </div>
              <h1 class="mt-4 mb-5">The Originals.<br />Beauty, unveiled.</h1>
              <p class="mb-5">
                Each one of the 100 Originals has been imagined by Eva. And each
                of them gives claim to its physical counterpart: a hand-painted
                varnished acrylic portrait that ships to your house.
              </p>
              <a href="/mint">
                <div v-if="todayIs30" class="colorfull">PRE-MINT MAY 30</div>
                <div v-if="!todayIs30" class="colorfull">MINT</div></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-color grid-nft pb-5">
      <div class="container">
        <div class="originals-container hideMobile">
          <div
            v-for="(item, index) in musess"
            :key="index"
            @click="showModal(item)"
          >
            <div class="nft-card-box">
              <div class="nft-preview">
                <img :src="item.image" alt="" />
                <div class="btn-eye"><i class="fa-solid fa-eye"></i></div>
              </div>
              <div class="mt-3 text-start">
                <div class="tag mb-3">ORIGINAL</div>
                <p>{{ item.name }}</p>
                <h6>
                  {{ item.attributes[1].value }}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="hideDesktop">
          <div class="row">
            <div
              class="col-6 p-0 m-0"
              v-for="(item, index) in musess"
              :key="index"
              @click="showModal(item)"
            >
              <div class="nft-card-box">
                <div class="nft-preview">
                  <img :src="item.image" alt="" />
                  <div class="btn-eye"><i class="fa-solid fa-eye"></i></div>
                </div>
                <div class="mt-3 text-start">
                  <div class="tag mb-3">ORIGINAL</div>
                  <p>{{ item.name }}</p>
                  <h6>
                    {{ item.attributes[1].value }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="big-title originals-text">Originals</div>
    </section>

    <section class="bg-color grid-nft pt-5 pb-5">
      <div class="container">
        <div class="originals-container hideMobile">
          <div
            v-for="(item, index) in musessTWO"
            :key="index"
            @click="showModal(item)"
          >
            <div class="nft-card-box">
              <div class="nft-preview">
                <img :src="item.image" alt="" />
                <div class="btn-eye"><i class="fa-solid fa-eye"></i></div>
              </div>
              <div class="mt-3 text-start">
                <div class="tag mb-3">ORIGINAL</div>
                <p>{{ item.name }}</p>
                <h6>
                  {{ item.attributes[1].value }}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="hideDesktop">
          <div class="row">
            <div
              class="col-6 p-0 m-0"
              v-for="(item, index) in musessTWO"
              :key="index"
              @click="showModal(item)"
            >
              <div class="nft-card-box">
                <div class="nft-preview">
                  <img :src="item.image" alt="" />
                  <div class="btn-eye"><i class="fa-solid fa-eye"></i></div>
                </div>
                <div class="mt-3 text-start">
                  <div class="tag mb-3">ORIGINAL</div>
                  <p>{{ item.name }}</p>
                  <h6>
                    {{ item.attributes[1].value }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="big-title originals-text reverse">Originals</div>
    </section>

    <section class="bg-color grid-nft pt-5 pb-5">
      <div class="container">
        <div class="originals-container hideMobile">
          <div
            v-for="(item, index) in musessTHREE"
            :key="index"
            @click="showModal(item)"
          >
            <div class="nft-card-box">
              <div class="nft-preview">
                <img :src="item.image" alt="" />
                <div class="btn-eye"><i class="fa-solid fa-eye"></i></div>
              </div>
              <div class="mt-3 text-start">
                <div class="tag mb-3">ORIGINAL</div>
                <p>{{ item.name }}</p>
                <h6>
                  {{ item.attributes[1].value }}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="hideDesktop">
          <div class="row">
            <div
              class="col-6 p-0 m-0"
              v-for="(item, index) in musessTHREE"
              :key="index"
              @click="showModal(item)"
            >
              <div class="nft-card-box">
                <div class="nft-preview">
                  <img :src="item.image" alt="" />
                  <div class="btn-eye"><i class="fa-solid fa-eye"></i></div>
                </div>
                <div class="mt-3 text-start">
                  <div class="tag mb-3">ORIGINAL</div>
                  <p>{{ item.name }}</p>
                  <h6>
                    {{ item.attributes[1].value }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="big-title originals-text">Originals</div>
    </section>

    <div class="gap bg-color"></div>
    <div class="gap bg-color"></div>

    <MarqueeText
      class="banner-container bg-color project-banner"
      :duration="30"
    >
      <span class="banner-text">
        THE ORIGINALS <b> THE ORIGINALS</b> THE ORIGINALS
        <b> THE ORIGINALS</b> THE ORIGINALS <b> THE ORIGINALS</b> THE ORIGINALS
        <b> THE ORIGINALS</b> THE ORIGINALS
      </span>
    </MarqueeText>

    <section class="originals" style="position: relative">
      <div class="container px-5 pt-5 no-padding-x-mobile" id="ai_made">
        <div class="row align-items-center justify-content-between">
          <div
            class="col-12 col-md-12 col-lg-6 text-start order-last order-lg-first"
          >
            <div class="p-5">
              <h5 class="nm-subtitle mb-5 hideMobile">
                BEAUTY<br />
                REIMAGINED<br />
                BY AN AI
              </h5>

              <LoopAiMade />
            </div>
          </div>
          <div
            class="col-12 col-md-12 col-lg-6 text-start order-first order-lg-last"
          >
            <div class="p-5 mt-5 no-padding-y-mobile">
              <div class="d-flex align-items-baseline">
                <div class="line"></div>
                <h5 class="ms-1">AI-MADE</h5>
              </div>
              <h2 class="mt-4 mb-5">The AI-Painted collection</h2>
              <p class="mt-4 mb-5">
                899 parallel Musess painted by an AI in different styles and
                through different color palettes pushing forward the boundaries
                of traditional beauty.
              </p>

              <a href="/ai-made"
                ><div class="mint-button mt-5">VIEW MORE</div></a
              >
            </div>
          </div>
        </div>
        <div class="gap"></div>
        <div class="gap hideMobile"></div>
        <div class="big-title text-start aimade-text">AI-made</div>
      </div>
    </section>

    <modal name="musa">
      <div class="container" v-if="selected.attributes !== undefined">
        <div class="d-flex justify-content-between align-items-center">
          <h1 class="text-start mb-4">
            {{ selected.attributes[1].value }}
          </h1>
          <i @click="closeModal()" class="fa-solid fa-xmark hideDesktop"></i>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-5">
            <div class="box-img-modal">
              <img :src="selected.image" alt="" />
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-7 text-start">
            <div class="d-flex align-items-baseline justify-content-between">
              <div class="d-flex align-items-baseline">
                <div class="line"></div>
                <h5 class="ms-1 mt-3">
                  {{ selected.attributes[0].value }}
                </h5>
              </div>
              <i @click="closeModal()" class="fa-solid fa-xmark hideMobile"></i>
            </div>
            <div class="mt-3 mb-5">
              <h2>{{ selected.name }}</h2>
              <div class="mt-5">
                <h2 class="mt-3">
                  {{ selected.description.split("\n\n")[0] }}
                </h2>
                <p class="mt-3">{{ selected.description.split("\n\n")[1] }}</p>
                <div class="d-flex align-items-baseline">
                  <p>Written by:</p>
                  <p class="ms-2" style="font-weight: 900; font-size: 1.1rem">
                    {{ selected.attributes[4].value }}
                  </p>
                </div>
              </div>
            </div>
            <a href="/mint">
              <div v-if="todayIs30" class="colorfull">PRE-MINT MAY 30</div>
              <div v-if="!todayIs30" class="colorfull">MINT</div></a
            >
          </div>
        </div>
      </div>
    </modal>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MarqueeText from "vue-marquee-text-component";
import LoopAiMade from "@/components/LoopAiMade.vue";
import checkDate from "@/mixins/checkDate";
import axios from "axios";

export default {
  name: "Originals",
  mixins: [checkDate],
  components: {
    Header,
    Footer,
    MarqueeText,
    LoopAiMade,
  },
  data() {
    return {
      musess: [],
      musessTWO: [],
      musessTHREE: [],
      selected: {},
      titleONE: "",
      storyONE: "",
    };
  },
  async mounted() {
    const app = this;
    app.isDateToday();
    const response = await axios.get(process.env.VUE_APP_API_LIST_ONE);
    const responseTwo = await axios.get(process.env.VUE_APP_API_LIST_TWO);
    const responseThree = await axios.get(process.env.VUE_APP_API_LIST_THREE);

    let list = response.data.list;
    let nfts = response.data.nfts;

    let listTwo = responseTwo.data.list;
    let nftsTwo = responseTwo.data.nfts;

    let listThree = responseThree.data.list;
    let nftsThree = responseThree.data.nfts;

    for (let k of list) {
      app.musess.push(nfts[k]);
    }
    // for (let i in app.musess) {
    //   let desc = app.musess[i].description.split("\n\n");
    //   app.musess.push(desc[i]);

    //   console.log(desc)
    // }
    for (let y of listTwo) {
      app.musessTWO.push(nftsTwo[y]);
    }
    for (let z of listThree) {
      app.musessTHREE.push(nftsThree[z]);
    }

    console.log(app.musess);
  },
  methods: {
    showModal(item) {
      this.selected = item;
      this.$modal.show("musa");
    },
    closeModal() {
      this.$modal.hide("musa");
    },
  },
};
</script>
