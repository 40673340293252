<template>
  <div class="footer">
    <div
      class="margin border-bottom-black"
      :class="{ 'bg-color': isOriginalPage || isAiMade || isMint }"
      style="height: 11rem; width: 100%; position: relative"
    >
      <div v-if="isHome" @click="topFunction()">
        <div class="scroll-top hideMobile">
          <img src="../assets/img/back_black.svg" alt="" />
        </div>
      </div>

      <div v-if="!isHome" @click="topFunction()">
        <div class="scroll-top hideMobile">
          <img src="../assets/img/vec_back.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="p-5">
      <div
        class="d-flex flex-column flex-md-row flex-lg-row justify-content-between"
      >
        <div class="text-start">
          <div class="footer-brand">MUSESS</div>
          <p class="mt-4">
            Celebrating Women’s Beauty.<br />
            999 times.
          </p>
        </div>
        <div class="text-start test-lg-end">
          <a href="/whitepaper_musess.pdf" target="_blank" class="footer-link"
            >Whitepaper</a
          >
          <a
            href="https://etherscan.io/address/0x89d2167554e2A91da051b92863019D199aa17C8c#code"
            class="footer-link"
            >Smart Contract</a
          >
          <a
            href="https://www.iubenda.com/privacy-policy/33676418"
            target="_blank"
            class="footer-link"
            >Privacy Policy</a
          >
          <a
            href="/musess_terms__conditions.pdf"
            target="_blank"
            class="footer-link"
            >Terms & Conditions</a
          >
        </div>
        <div class="d-flex">
          <a
            class="social-icon-inverted"
            href="https://twitter.com/Musess_art"
            target="_blank"
            ><i class="fa-brands fa-twitter"></i
          ></a>
          <a
            class="social-icon-inverted"
            href="https://www.instagram.com/musess_art/"
            target="_blank"
            ><i class="fa-brands fa-instagram"></i
          ></a>
          <a
            class="social-icon-inverted"
            href="https://discord.com/invite/musess"
            target="_blank"
            ><i class="fa-brands fa-discord"></i
          ></a>
          <a
            class="social-icon-spatial spatial-light"
            href="https://spatial.io/s/Musess-the-Impossible-Exhibition-6256acb21113e700013e80de?share=8505081060972342593"
            target="_blank"
          ></a>
          <a
            class="social-icon-opensea opensea-light"
            href="https://opensea.io/collection/musessart"
            target="_blank"
          ></a>
        </div>
      </div>
    </div>
    <div
      class="bg-color p-5 d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between"
    >
      <div class="copyright">
        <p>
          <b>© {{ new Date().getFullYear() }} IMPSSBL Corp. </b> All Rights
          Reserved.
        </p>
        <!-- <div id="mc_embed_signup">
          <form
            action="https://proofofstory.us5.list-manage.com/subscribe/post?u=0910fce3a51510bfc3bcc8ddb&amp;id=4bd091b827"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
          >
            <div id="mc_embed_signup_scroll">
              <div class="mc-field-group">
                <input
                  type="email"
                  value=""
                  name="EMAIL"
                  class="required email"
                  id="mce-EMAIL"
                  placeholder="name@gmail.com"
                />
              </div>
              <div class="clear">
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button"
                />
              </div>
            </div>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
            <div style="position: absolute; left: -5000px" aria-hidden="true">
              <input
                type="text"
                name="b_0910fce3a51510bfc3bcc8ddb_4bd091b827"
                tabindex="-1"
                value=""
              />
            </div>
          </form>
        </div> -->
      </div>
      <a href="https://www.impssbl.com/" target="_blank">
        <img class="logo" src="../assets/img/logo.svg" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isOriginalPage: false,
      isAiMade: false,
      isHome: false,
      isMint: false,
    };
  },
  mounted() {
    const app = this;
    if (app.$route.name === "Originals") {
      app.isOriginalPage = true;
    }
    if (app.$route.name === "AiMade") {
      app.isAiMade = true;
    }
    if (app.$route.name === "Home") {
      app.isHome = true;
    }
    if (app.$route.name === "MintingPage") {
      app.isMint = true;
    }
    if (app.$route.name === "Profile") {
      app.isMint = true;
    }
  },
  methods: {
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
