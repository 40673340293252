import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import abi from "../ABI/abi.json";
import axios from "axios";

export default {
  data() {
    return {
      isDebug: true,
      isWorking: false,
      workingMessage: "",
      isWhitelistSale: "",
      isSale: "",
      ABI: abi,
      network: "ethereum",
      price: "",
      contract: process.env.VUE_APP_CONTRACT_ADDRESS,
      infuraId: "82a84b618fd94a76bf555a441eaaf25b",
      account: "",
      web3: "",
      networks: {
        ethereum: 1,
        rinkeby: 4,
        polygon: 137,
        mumbai: 80001,
        ganache: 5777,
      },
    };
  },
  methods: {
    async connect() {
      const app = this;
      let providerOptions = {};
      if (app.infuraId !== undefined) {
        console.log("Using infuraid:", app.infuraId);
        providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: app.infuraId,
            },
          },
        };
      }
      // Instantiating Web3Moda
      app.isWorking = true;
      app.workingMessage =
        "Connecting... Please confirm connection in your wallet";
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providerOptions,
      });
      const provider = await web3Modal.connect();
      app.web3 = await new Web3(provider);
      // Checking if networkId matches
      const netId = await app.web3.eth.net.getId();
      app.workingMessage = "Checking Network...";
      if (parseInt(netId) !== app.networks[app.network]) {
        alert("Switch to " + app.network + " network!");
      } else {
        const accounts = await app.web3.eth.getAccounts();
        try {
          let responseConnect = await axios.post(
            "https://8a9fvts87d.execute-api.us-east-1.amazonaws.com/dev/admit/connect",
            {
              address: accounts[0],
            }
          );
          console.log("Connessione", responseConnect);
        } catch (e) {
          console.log(e);
        }

        app.workingMessage = "Checking if Sale or Pre-sale are active...";
        // Checking Sale active
        const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
        app.isSale = await nftContract.methods.sale_active().call();
        if (app.isSale !== undefined && app.isSale === true) {
          app.workingMessage = "";
          app.isWorking = false;
        } else {
          app.workingMessage = "Sale is Not Active! Come back later";
          app.isWorking = false;
        }

        if (accounts.length > 0) {
          app.account = accounts[0];
          app.$root.$emit("web3connected", accounts[0]);
        }
      }
      // SET LOGGED ON LOCALSTORAGE

      if (app.account !== undefined && app.account.length > 0) {
        localStorage.setItem("connected", true);
        localStorage.setItem("account-connected", app.account);
      } else {
        localStorage.setItem("connected", false);
        localStorage.setItem("account-connected") === null;
      }
      if (
        window.location.href.indexOf("mint") === -1 &&
        window.location.href.indexOf("profile") === -1 &&
        window.location.href.indexOf("claim") === -1
      ) {
        window.location.href = "/mint";
      }
      if (app.web3.eth.accounts[0] !== app.account) {
        if (window.ethereum !== undefined) {
          window.ethereum.on("accountsChanged", function (accounts) {
            app.account = accounts[0];
            localStorage.setItem("account-connected", app.account);
            localStorage.setItem("connected", true);
            console.log("Account changed");
            window.location.reload();
          });
        }
      }
    },
  },
};
