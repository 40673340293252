export default {
  data() {
    return {
      todayIs30: false,
    };
  },
  methods: {
    isDateToday() {
      const app = this;
      const otherDate = new Date("30 May 2022");
      const todayDate = new Date();

      if (
        otherDate.getDate() === todayDate.getDate() &&
        otherDate.getMonth() === todayDate.getMonth() &&
        otherDate.getYear() === todayDate.getYear()
      ) {
        app.todayIs30 = true;
      } else {
        app.todayIs30 = false;
      }

    },
  },
};
