<template>
  <div id="user_profile" :class="{ 'max-height': isWorking }">
    <Header />

    <div class="bg-color" :class="{ 'p-5': !isMobile, 'pt-5': isMobile }">
      <!-- Working Message -->
      <div class="container-workingMessage" v-if="isWorking">
        <div class="row justify-content-center">
          <div class="col-10 col-md-10 col-lg-7">
            <div class="workingMessage">
              <i class="fas fa-spinner fa-pulse"></i>
              <h2 class="mt-3">{{ workingMessage }}</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- End Working Message -->

      <section
        v-if="!isWorking"
        class="bg-color"
        :class="{ 'px-5': !isMobile }"
      >
        <div class="container p-5">
          <!-- NO NFTS -->
          <div class="nonfts" v-if="account && ownedNFT.length === 0">
            <!-- TITLE -->
            <div class="row">
              <div class="col-12 col-md-12 col-lg-8 text-start">
                <div class="mt-5 no-padding-x-mobile">
                  <div class="d-flex align-items-baseline">
                    <div class="line"></div>
                    <h5 class="ms-1">REDEEM</h5>
                  </div>
                  <h1 class="mt-4 mb-4">
                    You do not have any NFT or eligible original musess for
                    physical redemption.
                  </h1>
                </div>
              </div>
            </div>
            <div
              class="mt-5 py-5 border-top-white border-bottom-white"
              style="position: relative"
            >
              <div class="row align-items-center">
                <div class="col-12 col-md-6 col-lg-4 text-start">
                  <h6 class="mb-5" style="line-height: 1.2">
                    Mint a physical/digital Musess instead.
                  </h6>
                  <a href="/mint">
                    <div v-if="todayIs30" class="mint-button">
                      PRE-MINT MAY 30
                    </div>
                    <div v-if="!todayIs30" class="mint-button">MINT</div></a
                  >
                </div>
                <div class="col-12 col-md-6 col-lg-8">
                  <img
                    src="../assets/img/transparent_musess.png"
                    alt=""
                    width="100%"
                  />
                </div>
              </div>
              <div class="absolute-bottom-right">
                <img src="../assets/img/smiley-nervous-thin.svg" alt="" />
              </div>
            </div>
            <!-- END TITLES -->
          </div>
          <!-- END NO NFTS -->

          <div
            v-if="
              account &&
              metadataNFT !==
                'https://musess-api-otpxj.ondigitalocean.app/nft/unrevealed' &&
              ownedNFT.length > 0
            "
          >
            <!-- TITLE -->
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 text-start">
                <div class="mt-5 no-padding-x-mobile">
                  <div class="d-flex align-items-baseline">
                    <div class="line"></div>
                    <h5 class="ms-1">REDEEM</h5>
                  </div>
                  <h1 class="mt-4 mb-4">Your Musess.</h1>
                  <p>
                    If you own any Original Musess they will appear here and you
                    will be able to redeem their physical, hand-painted portrait
                    made by Eva in Italy.
                  </p>
                </div>
              </div>
            </div>
            <!-- END TITLES -->

            <div class="back-button d-flex align-items-end my-5">
              <div v-if="redeemSelected">
                <div
                  class="unselect-btn"
                  style="cursor: pointer"
                  @click="redeemSelected = false"
                >
                  <img
                    width="60px"
                    class="me-4"
                    src="../assets/img/vec_back.svg"
                    alt=""
                  />
                </div>
              </div>
              <h3 class="text-start">REDEEM ORIGINAL MUSESS</h3>
            </div>

            <!-- ORIGINALS NFTS -->
            <Transition
              name="custom-fade"
              enter-active-class="animate__animated animate__fadeIn"
            >
              <div v-show="!redeemSelected">
                <div v-for="original in ownedNFT" :key="original">
                  <div
                    class="row border-top-white align-items-center no-gutters py-4 mt-4"
                    v-if="
                      metadataNFT[original] !== undefined &&
                      metadataNFT[original].attributes[2].value === 'Original'
                    "
                  >
                    <div class="col-4 col-md-4 col-lg-3 col-xl-2 ps-0">
                      <div class="nft-container">
                        <img :src="metadataNFT[original].image" alt="" />
                      </div>
                    </div>
                    <div
                      class="col-8 col-md-8 col-lg-4 col-xl-4 text-start justify-content-md-end"
                    >
                      <div>
                        <p class="">{{ metadataNFT[original].name }}</p>
                        <h4 class="">
                          {{ metadataNFT[original].attributes[1].value }}
                        </h4>
                        <h2 class="py-4">✦</h2>
                        <h4 class="">
                          {{
                            metadataNFT[original].description.split("\n\n")[0]
                          }}
                        </h4>
                      </div>
                    </div>
                    <div
                      class="col-6 col-md-5 col-lg-3 col-xl-3 ms-auto pe-0 text-end"
                      :class="{ 'text-center mt-5': isTablet || isMobile }"
                    >
                      <div
                        v-if="redeemable"
                        class="mint-button"
                        @click="selectMusess(original)"
                      >
                        REDEEM
                      </div>
                      <div
                        v-if="!redeemable"
                        class="mint-button"
                        style="cursor: default"
                      >
                        REDEEMABLE SOON
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <!-- END ORIGINALS NFTS -->

            <!-- AI NFTS PRINTING -->
            <Transition
              name="custom-fade"
              enter-active-class="animate__animated animate__fadeIn"
            >
              <div v-show="!redeemSelected">
                <h3 class="text-start mt-5 mb-4">PURCHASE AI PRINTS</h3>
                <p class="text-start my-4">
                  If you own one or more AI-painted Musess, you will be able to
                  purchase a physical, high-quality print for your Musess.
                </p>
                <!-- TODO: -->
                <!-- <p>Please select the Musess you want to buy prints for.</p> -->
                <div
                  class="row grid-ai-nfts py-5 border-top-white border-bottom-white no-gutters"
                >
                  <div
                    v-for="aipaint in ownedNFT"
                    :key="aipaint"
                    class="col-6 col-md-4 col-lg-3 col-xl-2 p-2 mb-3"
                  >
                    <div
                      v-if="
                        metadataNFT[aipaint] !== undefined &&
                        metadataNFT[aipaint].attributes[2].value !== 'Original'
                      "
                      class="box-ai-made"
                    >
                      <img
                        class="ai-img"
                        :src="metadataNFT[aipaint].image"
                        alt=""
                      />
                      <div v-if="!isMobile || !isTablet" class="hover-box">
                        <div class="title-box">
                          <p>{{ metadataNFT[aipaint].name }}</p>
                          <h4>
                            {{ metadataNFT[aipaint].attributes[1].value }}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div v-if="isMobile || isTablet" class="mt-4">
                      <p>{{ metadataNFT[aipaint].name }}</p>
                      <h4>
                        {{ metadataNFT[aipaint].attributes[1].value }}
                      </h4>
                    </div>
                    <!-- TODO: Rimettere il pulsante quando sarà disponibile il servizio-->
                    <!-- <div class="mint-button mt-4">BUY PRINT</div> -->
                  </div>
                </div>
              </div>
            </Transition>
            <!-- AI NFTS PRINTING -->

            <!-- REDEEM SECTION -->
            <Transition
              name="custom-fade"
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
            >
              <div v-if="redeemSelected" id="redeem-form">
                <!-- SELECTED MUSESS -->
                <!-- <div
                class="row border-bottom-white align-items-center no-gutters pb-5 mt-5"
              >
                <div class="col-4 col-md-4 col-lg-2 col-xl-2 ps-0">
                  <div class="nft-container">
                    <img src="../assets/img/muse1.png" alt="" />
                  </div>
                </div>
                <div
                  class="col-8 col-md-8 col-lg-4 col-xl-4 text-start justify-content-md-end ps-5"
                >
                  <div :class="{ 'ms-4': !isMobile }">
                    <p class="">Originals Musess #1</p>
                    <h4 class="">Alice</h4>
                    <h2 class="py-4">✦</h2>
                    <h4 class="">GRASS IS A SIMPLE THING LONG TITLE</h4>
                  </div>
                </div>
                <div
                  class="col-6 col-md-5 col-lg-3 col-xl-3 ms-auto pe-0 text-end"
                  :class="{ 'text-center mt-5': isTablet || isMobile }"
                >
                  <div class="mint-button" style="cursor: default">
                    REDEEMING
                  </div>
                </div>
              </div> -->
                <!-- END SELECTED MUSESS -->
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-6 text-start">
                    <div class="mt-5 mb-5 no-padding-x-mobile">
                      <h1 class="mt-4 mb-4">Redeem Physical Piece.</h1>
                      <p>
                        To receive the Original Musess fill in the form below.
                        You will receive a confirmation e-mail once the process
                        has been completed.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-10 col-xl-9">
                    <div class="redeem-form text-start">
                      <!-- NAME -->
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="mb-3">
                            <label for="nameInput" class="form-label"
                              >Name</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="nameInput"
                              v-model="name"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="mb-3">
                            <label for="lastnameInput" class="form-label"
                              >Lastname</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="lastnameInput"
                              v-model="lastname"
                            />
                          </div>
                        </div>

                        <!-- STREET E NO. -->

                        <div class="col-12 col-md-8 col-lg-9">
                          <div class="mb-3">
                            <label for="streetInput" class="form-label"
                              >Street</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="streetInput"
                              v-model="address"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                          <div class="mb-3">
                            <label for="nameInput" class="form-label"
                              >Apartment No.</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="civicInput"
                              v-model="code_apartament"
                            />
                          </div>
                        </div>

                        <!-- CITY ETC. -->

                        <div class="col-12 col-md-5 col-lg-5">
                          <div class="mb-3">
                            <label for="cityInput" class="form-label"
                              >City</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="cityInput"
                              v-model="city"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-5 col-lg-5">
                          <div class="mb-3">
                            <label for="countryInput" class="form-label"
                              >Country</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="countryInput"
                              v-model="country"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-2 col-lg-2">
                          <div class="mb-3">
                            <label for="zipInput" class="form-label"
                              >ZIP Code</label
                            >
                            <input
                              type="number"
                              class="form-control"
                              id="zipInput"
                              v-model="zip_code"
                            />
                          </div>
                        </div>

                        <!-- PHONE, MAIL -->

                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="mb-3">
                            <label for="phoneInput" class="form-label"
                              >Phone Number</label
                            >
                            <input
                              type="phone"
                              class="form-control"
                              id="phoneInput"
                              v-model="telephone_number"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="mb-3">
                            <label for="emailInput" class="form-label"
                              >E-mail Address</label
                            >
                            <input
                              type="email"
                              class="form-control"
                              id="emailInput"
                              v-model="firstEmail"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="mb-3">
                            <label for="emailInput" class="form-label"
                              >Confirm E-mail Address</label
                            >
                            <input
                              type="email"
                              class="form-control"
                              id="emailInput"
                              v-model="checkEmail"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-start mt-5 pb-5">
                  Need support?
                  <a href="mailto:team@musess.art" class="white">Contact Us</a>
                </p>
                <div class="text-start mt-5">
                  <div class="mint-button" @click="submitForm()">SUBMIT</div>
                </div>
              </div>
            </Transition>
            <!-- REDEEM SECTION -->
          </div>

          <!-- NFTS UNREVEALED -->
          <div
            v-if="
              account &&
              metadataNFT ===
                'https://musess-api-otpxj.ondigitalocean.app/nft/unrevealed' &&
              ownedNFT.length > 0
            "
          >
            <!-- AI NFTS PRINTING -->
            <!-- TITLE -->
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 text-start">
                <div class="mt-5 no-padding-x-mobile">
                  <div class="d-flex align-items-baseline">
                    <div class="line"></div>
                    <h5 class="ms-1">YOUR NFTs</h5>
                  </div>
                  <h1 class="mt-4 mb-4">UNREVEALED MUSESS.</h1>
                  <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam.
              </p> -->
                </div>
              </div>
            </div>
            <!-- END TITLES -->
            <Transition
              name="custom-fade"
              enter-active-class="animate__animated animate__fadeIn"
            >
              <div v-show="!redeemSelected">
                <h3 class="text-start mt-5 mb-4">THIS IS YOUR NFTS:</h3>
                <div
                  class="row grid-ai-nfts py-5 border-top-white border-bottom-white no-gutters"
                >
                  <div
                    v-for="item in ownedNFT"
                    :key="item"
                    class="col-6 col-md-4 col-lg-3 col-xl-2 p-2 mb-3"
                  >
                    <div class="box-ai-made">
                      <img
                        class="ai-img"
                        src="../assets/img/unrevealed.jpg"
                        alt=""
                      />
                      <div v-if="!isMobile || !isTablet" class="hover-box">
                        <div class="title-box">
                          <p>Unrevealed Musess</p>
                          <h4>#{{ item }}</h4>
                        </div>
                      </div>
                    </div>
                    <div v-if="isMobile || isTablet" class="mt-4">
                      <p>Unrevealed Musess</p>
                      <h4>#{{ item }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <!-- AI NFTS PRINTING -->
          </div>
          <!-- TODO: riabilitare il check originals quando completo  v-if="account && ownedNFT.length === 0" fare il controllo se sono originals-->
          <!-- <div>
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 text-start">
                <div class="mt-5 no-padding-x-mobile">
                  <div class="d-flex align-items-baseline">
                    <div class="line"></div>
                    <h5 class="ms-1">REDEEM</h5>
                  </div>
                  <h1 class="mt-4 mb-4">
                    You do not have eligible original musess for physical
                    redemption.
                  </h1>
                </div>
              </div>
            </div>
            <div
              class="mt-5 pt-5 pb-5 border-top-white border-bottom-white no-padding-x-mobile"
            >
              <div class="row">
                <div class="col-12 col-md-6 col-lg-3 text-start">
                  <h3>
                    Buy a physical/digital print of your AI Musess instead.
                  </h3>
                  <div class="mt-5">
                    <a href="/mint"> <div class="mint-button">BUY NOW</div></a>
                  </div>
                </div>
              </div>
              <h2 class="mb-2">You have to connect your wallet first</h2>
            </div>
          </div> -->

          <div v-if="!account">
            <div class="gap"></div>
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 text-start">
                <div class="mt-5 no-padding-x-mobile">
                  <div class="d-flex align-items-baseline">
                    <div class="line"></div>
                    <h5 class="ms-1">PLEASE CONNECT</h5>
                  </div>
                  <h1 class="mt-4 mb-4">
                    You have to connect your wallet first
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="isWorking">
        <div class="gap"></div>
        <div class="gap"></div>
        <div class="bg-color"></div>
        <div class="gap"></div>
        <div class="gap"></div>
      </section>
    </div>
    <div class="gap bg-color"></div>
    <!-- BANNER -->
    <MarqueeText
      class="banner-container-inverted project-banner"
      :duration="30"
      :repeat="30"
    >
      <span class="banner-text"> REDEEM <b>MUSESS</b>&nbsp; </span>
    </MarqueeText>
    <!-- END BANNER -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MarqueeText from "vue-marquee-text-component";
import checkViewport from "@/mixins/checkViewport";
import connectWallet from "@/mixins/connectWallet";
import checkDate from "@/mixins/checkDate";
import axios from "axios";

export default {
  name: "Profile",
  mixins: [checkViewport, connectWallet, checkDate],
  components: {
    Header,
    Footer,
    MarqueeText,
  },
  data() {
    return {
      redeemSelected: false,
      selected: {},
      tokenId: "",
      //form
      name: "",
      lastname: "",
      address: "",
      code_apartament: "",
      city: "",
      country: "",
      zip_code: "",
      telephone_number: "",
      firstEmail: "",
      checkEmail: "",
      correctEmail: "",
      ownedNFT: [],
      metadataNFT: {},
      redeemable: false,
    };
  },
  mounted() {
    // check account connected
    const app = this;
    if (localStorage.getItem("connected", false)) {
      app.connect();
    }
    const now = new Date().getTime();
    if (now > 1654704000000) {
      app.redeemable = true;
    }
    app.$root.$on("web3connected", (account) => {
      if (app.account.length === 0) {
        app.account = account;
        app.connect();
      } else {
        app.isWorking = true;
        app.workingMessage = "Fetching all NFTs from blockchain...";
        app.checkNFT();
      }
    });

    // end check account
  },
  methods: {
    selectMusess(original) {
      const app = this;
      app.selected = original;
      app.tokenId = app.selected;
      app.redeemSelected = true;
      if (app.isDebug === true) {
        console.log("I have selected the musess:", app.selected);
        console.log(
          "The token ID of selected musess is:",
          app.selected.tokenId
        );
      }
    },

    async submitForm() {
      const app = this;
      console.log("init function");
      try {
        const signature = await app.web3.eth.personal.sign(
          app.tokenId.toString(),
          app.account
        );
        if (
          app.name.length > 0 &&
          app.lastname.length > 0 &&
          app.address.length > 0 &&
          app.code_apartament.length > 0 &&
          app.city.length > 0 &&
          app.country.length > 0 &&
          app.zip_code.length > 0 &&
          app.telephone_number.length > 0 &&
          app.firstEmail.length > 0 &&
          app.checkEmail.length > 0
        ) {
          console.log(
            "first mail",
            app.firstEmail,
            "second mail",
            app.checkEmail
          );
          if (
            app.firstEmail.toLowerCase().trim() ===
            app.checkEmail.toLowerCase().trim()
          ) {
            try {
              app.isWorking = true;
              app.workingMessage = "Sending redeem request, please wait...";
              setTimeout(function () {
                app.isWorking = false;
                app.workingMessage = false;
              }, 3000);
              console.log({
                name: app.name,
                surname: app.lastname,
                email: app.checkEmail,
                address: app.address,
                code: app.code_apartament,
                city: app.city,
                country: app.country,
                zip: app.zip_code,
                phone: app.telephone_number,
              });
              let response = await axios.post(process.env.VUE_APP_API_REDEEM, {
                request: {
                  name: app.name,
                  surname: app.lastname,
                  email: app.checkEmail,
                  address: app.address,
                  code: app.code_apartament,
                  city: app.city,
                  country: app.country,
                  zip: app.zip_code,
                  phone: app.telephone_number,
                },
                signature: signature,
                token: app.tokenId.toString(),
              });
              console.log(response.data);
              alert(response.data.message);
            } catch (e) {
              alert(e.message);
            }
          } else {
            app.isWorking = true;
            app.workingMessage =
              "The e-mail addresses that you have inserted doesen't match. Please retry!";
            setTimeout(function () {
              app.isWorking = false;
              app.workingMessage = false;
            }, 3000);

            console.log("non metchano le mail");
          }
        } else {
          app.isWorking = true;
          app.workingMessage =
            "Please check whether you have entered all data in the form and retry!";
          setTimeout(function () {
            app.isWorking = false;
            app.workingMessage = false;
          }, 3000);
        }
      } catch (e) {
        alert(e.message);
        window.location.reload();
      }
    },

    async checkNFT() {
      const app = this;
      const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
      app.ownedNFT = await nftContract.methods
        .tokensOfOwner(app.account)
        .call();
      if (app.ownedNFT.length === 0) {
        console.log("you don't have any nft");
        app.isWorking = false;
      } else {
        for (let k in app.ownedNFT) {
          let myNFTs = await axios.get(
            process.env.VUE_APP_API_MUSESS + app.ownedNFT[k]
          );

          app.metadataNFT[app.ownedNFT[k]] = myNFTs.data;
          // var string = "Original Musess";
          // var substring = "Original";

          // console.log(string.indexOf(substring) !== -1);
          console.log(
            app.metadataNFT[app.ownedNFT[k]].name.indexOf("Original") !== -1
          );

          console.log("All my nfts", app.metadataNFT);
        }
        console.log("this is owned nfts:", app.ownedNFT);
        app.isWorking = false;
      }
    },
  },
};
</script>
