<template>
  <div class="home">
    <Header />
    <div id="content">
      <section id="our_mission" class="main-section">
        <a href="#project">
          <div class="scroll-down left hideMobile">
            <img src="../assets/img/vec_back.svg" alt="" />
          </div>
        </a>
        <div class="container p-5">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-6 text-start">
              <div class="p-5 mt-5 no-padding-x-mobile">
                <div class="d-flex align-items-baseline">
                  <div class="line"></div>
                  <h5 class="ms-1">OUR MISSION</h5>
                </div>
                <h1 class="mt-4 mb-5">
                  Celebrating Women’s Beauty. 999 times.
                </h1>
                <a href="/mint">
                  <div v-if="todayIs30" class="mint-button">
                    PRE-MINT MAY 30
                  </div>
                  <div v-if="!todayIs30" class="mint-button">MINT</div></a
                >
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 align-self-end">
              <div class="nftgalleryHover mt-5">
                <video
                  id="videoElement"
                  preload="auto"
                  autoplay
                  loop
                  playsinline
                  muted
                  defaultMuted
                  @canplay="updatePaused"
                  @playing="updatePaused"
                  @pause="updatePaused"
                  @click="pause"
                  class="video"
                >
                  <source src="../assets/video/main_vid.mp4" type="video/mp4" />
                  <source
                    src="../assets/video/main_vid.webm"
                    type="video/webm"
                  />
                  <source src="../assets/video/main_vid.ogg" type="video/ogg" />
                </video>
                <div class="video-controls">
                  <div class="video-controls-btn" v-if="paused" @click="play">
                    <i class="fa-solid fa-play"></i>
                  </div>
                  <!-- <div class="video-controls-btn" v-if="!paused" @click="pause">
                    <i class="fa-solid fa-pause"></i>
                  </div> -->
                </div>
              </div>
              <div class="big-title trailer">Trailer</div>
            </div>
          </div>
        </div>
      </section>
      <section id="project" class="project">
        <div class="container px-5">
          <div class="row">
            <div
              class="col-12 col-md-12 col-lg-6 align-self-end order-last order-lg-first"
            >
              <img src="../assets/img/muse_main.jpg" alt="" />
            </div>
            <div
              class="col-12 col-md-12 col-lg-6 text-start order-first order-lg-last"
            >
              <div class="p-5 mt-5">
                <div class="d-flex align-items-baseline">
                  <div class="line"></div>
                  <h5 class="ms-1">THE MUSESS PROJECT</h5>
                </div>
                <h2 class="mt-4 mb-5">
                  The <br />
                  Project.
                </h2>
                <p class="mt-4 mb-5">
                  MUSESS is a collection of <b>999 portraits</b> of women that
                  celebrate a borderless, inclusive and unveiled beauty.
                </p>

                <hr />
                <p class="mb-5">
                  ◆ 100 Original, hand-drawn B/W portraits, and 899 AI-painted
                  ones.
                </p>
                <hr />
                <p class="mb-5">
                  ◆ The Originals give a <b>claim to a physical portrait</b>,
                  hand-painted on canvas in Italy and shipping worldwide.
                </p>
                <hr />
                <p class="mb-5">
                  ◆ Each NFT, whether Original or AI-Painted, comes with a
                  unique story. The Originals’ stories are
                  <b
                    >written by a group of talented female writers in web2 and
                    web3</b
                  >. The AI-portraits stories are imagined by an artificial
                  intelligence.
                </p>
                <hr />
                <p class="mb-5">
                  ◆ The <b>sale is blind</b>, meaning you can get an Original
                  (roughly 1 in 10) or an AI-Painted Musess without being able
                  to choose them. The price is <b>0.18 eth</b>. The presale
                  price is <b>0.15 eth</b>.
                  <b>PRE-MINT MAY 30. MAIN SALE MAY 31</b>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- BANNER -->
      <MarqueeText
        class="banner-container bg-color project-banner"
        :duration="30"
      >
        <span class="banner-text">
          EVA ADAMIAN&nbsp;<b>THE PAINTRESS&nbsp;</b>EVA ADAMIAN&nbsp;<b
            >THE PAINTRESS&nbsp;</b
          >EVA ADAMIAN<b>&nbsp;THE PAINTRESS&nbsp;</b>EVA ADAMIAN
          <b>&nbsp;THE PAINTRESS&nbsp;</b>
        </span>
      </MarqueeText>
      <!-- END BANNER -->

      <section id="paintress" class="paintress bg-color">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <div class="gap"></div>
              <div>
                <h3>The Paintress</h3>
                <p class="mt-5">
                  MUSESS is a collection imagined and hand-drawn by
                  <b>Eva Adamian</b>, an artist of Armenian heritage and
                  origins, now living in Italy.<br /><br />
                  The collection is inspired to the <b>research</b> of a new
                  paradigm for feminine beauty — one proud of her imperfections,
                  shapes, ethnicities, countries and stories.<br /><br />
                  The Original 100 portraits <b>hand-painted</b> by Eva were
                  later reimagined by an <b>AI engine</b> to produce other 899
                  stunning masterpieces, completing the entire collection and
                  redefining the boundaries of beauty through
                  <b>possible and impossible shapes</b>.
                </p>
              </div>
              <div class="gap"></div>
            </div>
          </div>
        </div>
        <div class="divider-cta">
          <hr />
          <a href="https://evaisnothere.tumblr.com/" target="_blank">
            <div class="mint-button negative-position">EVA ADAMIAN</div></a
          >
        </div>
        <div class="container pt-5 pb-5" style="position: relative">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <p style="font-size: 1.125rem">◆</p>
              <p class="mt-3 mb-3">
                The project has been powered by
                <a
                  style="color: white; text-decoration: none; font-weight: bold"
                  href="https://impssbl.com"
                  target="_blank"
                  >Impssbl</a
                >, a world class studio creating decentralized autonomous
                stories — NFT-projects that create a new generation of fiction
                led by communities.
              </p>
            </div>
          </div>
          <a href="#musess">
            <div class="scroll-down right hideMobile">
              <img src="../assets/img/vec_back.svg" alt="" />
            </div>
          </a>
        </div>
      </section>

      <section id="originals" class="originals">
        <div id="musess" class="container p-5">
          <div class="row align-items-center">
            <div class="col-12 col-md-12 col-lg-6 text-start">
              <div class="p-5 mt-5">
                <div class="d-flex align-items-baseline">
                  <div class="line"></div>
                  <h5 class="ms-1">THE ORIGINALS</h5>
                </div>
                <h2 class="mt-4 mb-5">
                  The Original<br />
                  Musess.
                </h2>
                <p class="mt-4 mb-5">
                  <b>
                    MUSESS is a collection of 999 portraits, of which 100
                    Originals.<br />
                  </b>
                  The Originals give you the right to claim the corresponding
                  physical painting.
                </p>

                <hr />
                <p class="mb-5">
                  ◆ Each physical portrait is hand-painted by Eva Adamian and
                  counts as a separate NFT, with its own QR code and its
                  physical story.
                </p>
                <hr />
                <p>
                  ◆ The stories of the Originals have been written by a talented
                  group of women writers in web3.
                </p>
                <a href="/originals-musess">
                  <div class="mint-button mt-5">SEE ORIGINAL MUSESS</div></a
                >
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 text-start originals-specs">
              <div class="big-title originals-text-home hideDesktop">
                Originals
              </div>
              <LoopOriginals />
            </div>
          </div>
          <div class="big-title originals-text hideMobile">Originals</div>
        </div>
        <div class="gap"></div>
        <div
          class="container p-5 no-padding-x-mobile"
          id="ai_made"
          style="position: relative"
        >
          <div class="row align-items-center justify-content-between">
            <div
              class="col-12 col-md-12 col-lg-5 text-start order-last order-lg-first"
            >
              <div class="container pt-5 pb-5 aimade-specs">
                <h5 class="nm-subtitle mb-5 hideMobile">
                  BEAUTY <br />REIMAGINED<br />
                  BY AN AI
                </h5>
                <div class="big-title text-start aimade-text-home hideDesktop">
                  AI-made
                </div>
                <LoopAiMade />
              </div>
            </div>
            <div
              class="col-12 col-md-12 col-lg-6 text-start order-first order-lg-last"
            >
              <div class="p-5 mt-5 no-padding-y-mobile">
                <div class="d-flex align-items-baseline">
                  <div class="line"></div>
                  <h5 class="ms-1">AI-MADE</h5>
                </div>
                <h2 class="mt-4 mb-5">
                  The AI-Painted<br />
                  Musess
                </h2>
                <p class="mt-4 mb-5">
                  Each one of the 100 Original Musess was reworked by an AI
                  Engine. The resulting 899 parallel Musess have been painted by
                  an AI in different styles and through different color
                  palettes, pushing forward the boundaries of traditional
                  beauty.
                </p>

                <a href="/ai-made">
                  <div class="mint-button mt-5">SEE AI MUSESS</div></a
                >
              </div>
            </div>
          </div>
          <div class="gap"></div>
          <div class="big-title text-start aimade-text hideMobile">AI-made</div>
        </div>
      </section>

      <section id="scene">
        <div class="video-container text-center">
          <video
            class="videoBG"
            preload="auto"
            loop
            autoplay
            muted
            defaultMuted
            playsinline
            style="height: 100%"
          >
            <source src="../assets/video/backstage.mp4" type="video/mp4" />
            <source src="../assets/video/backstage.ogg" type="video/ogg" />
            <source src="../assets/video/backstage.webm" type="video/webm" />
          </video>
        </div>
        <!-- BANNER -->
        <MarqueeText
          class="banner-container-inverted no-border-bottom"
          :duration="30"
        >
          <span class="banner-text" style="text-transform: uppercase"
            ><b>backstage</b>&nbsp;video&nbsp;
            <b>backstage</b>&nbsp;video&nbsp;<b>backstage</b>&nbsp;
            video&nbsp;<b>backstage</b>&nbsp;video&nbsp;
          </span>
        </MarqueeText>
      </section>

      <Roadmap />

      <section id="team" class="paintress bg-color border-top-white">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <div class="gap"></div>
              <div>
                <h3>The Impssbl Team</h3>
                <p class="mt-5">
                  <a href="https://impssbl.com" target="_blank">Impssbl</a> is a
                  web3 creative studio building decentralized autonomous
                  stories, i.e. NFT projects backed by stories that generate the
                  next generation of intellectual property rights.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container pt-5 pb-5 text-center text-md-start">
          <div class="row justify-content-center">
            <div class="col-12 col-md-2 mt-3">
              <h5>
                Alessandro Botteon
                <a
                  href="https://www.linkedin.com/in/alessandro-botteon-49731950/"
                  target="_blank"
                >
                  <i class="fa-brands fa-linkedin"></i
                ></a>
              </h5>
              <span>Founder, ex Google & Impossible Brands</span>
            </div>
            <div class="col-12 col-md-2 mt-3">
              <h5>
                Sebastiano Cataudo
                <a
                  href="https://www.linkedin.com/in/sebastiano-cataudo-1b404165/"
                  target="_blank"
                >
                  <i class="fa-brands fa-linkedin"></i
                ></a>
              </h5>
              <span>Co-Founder YOMI</span>
            </div>
            <div class="col-12 col-md-2 mt-3">
              <h5>
                Silvia Desideri
                <a
                  href="https://www.linkedin.com/in/silviadesideri/"
                  target="_blank"
                >
                  <i class="fa-brands fa-linkedin"></i
                ></a>
              </h5>
              <span>CREATIVE DIRECTOR </span>
            </div>
            <div class="col-12 col-md-2 mt-3">
              <h5>
                Abstrucked
                <a href="https://github.com/Abstrucked" target="_blank">
                  <i class="fa-brands fa-github"></i
                ></a>
              </h5>
              <span>HEAD OF COMMUNITY BUILDING </span>
            </div>
            <div class="col-12 col-md-2 mt-3">
              <h5>
                Sara Mengesha
                <a
                  href="https://www.linkedin.com/in/sara-mengesha-79238a1a/"
                  target="_blank"
                >
                  <i class="fa-brands fa-linkedin"></i
                ></a>
              </h5>
              <span>PARTNERSHIPS MANAGER, Yoni & The Resident Collective</span>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center pb-5">
            <div class="col-10 col-md-8 col-lg-4">
              <p class="text-start m-0">Subscribe to our newsletter</p>
              <div id="mc_embed_signup">
                <form
                  action="https://proofofstory.us5.list-manage.com/subscribe/post?u=0910fce3a51510bfc3bcc8ddb&amp;id=4bd091b827"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_blank"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll">
                    <div class="mc-field-group">
                      <input
                        type="email"
                        value=""
                        name="EMAIL"
                        class="required email"
                        id="mce-EMAIL"
                        placeholder="name@gmail.com"
                      />
                    </div>
                    <div class="clear">
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      />
                    </div>
                  </div>
                  <div id="mce-responses" class="clear">
                    <div
                      class="response"
                      id="mce-error-response"
                      style="display: none"
                    ></div>
                    <div
                      class="response"
                      id="mce-success-response"
                      style="display: none"
                    ></div>
                  </div>
                  <div
                    style="position: absolute; left: -5000px"
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_0910fce3a51510bfc3bcc8ddb_4bd091b827"
                      tabindex="-1"
                      value=""
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <Giveaway /> -->
    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import MarqueeText from "vue-marquee-text-component";
import checkDate from "@/mixins/checkDate";
import LoopOriginals from "@/components/LoopOriginals.vue";
import LoopAiMade from "@/components/LoopAiMade.vue";
import Roadmap from "@/components/Roadmap.vue";
// import Giveaway from "@/components/Giveaway.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  mixins: [checkDate],
  components: {
    Header,
    MarqueeText,
    LoopOriginals,
    LoopAiMade,
    Roadmap,
    // Giveaway,
    Footer,
  },
  data() {
    return {
      videoElement: null,
      paused: null,
    };
  },
  computed: {
    playing() {
      return !this.paused;
    },
  },
  mounted() {
    this.calculateHeader();
    this.isDateToday();

  },
  methods: {
    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },
    play() {
      this.videoElement.play();
    },
    pause() {
      this.videoElement.pause();
    },
    calculateHeader() {
      window
        .$(window)
        .resize(function () {
          var height = window.$("#navHead").height();
          window.$("#content").css({ "padding-top": height });
        })
        .trigger("resize");
    },
  },
};
</script>
