<template>
  <div class="coming-soon bg-color">
    <Header />
    <div class="container-workingMessage" v-if="isWorking">
      <div class="workingMessage">
        <i class="fas fa-spinner fa-pulse"></i>
        <h2 class="mt-3">{{ workingMessage }}...</h2>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10 col-md-10 col-lg-10">
            <div
              class="back-button d-flex align-items-center justify-content-center mb-5"
            >
              <a href="/">
                <img
                  width="60px"
                  class="me-4"
                  src="../assets/img/vec_back.svg"
                  alt=""
                />
              </a>
              <h4 class="m-0 white">BACK</h4>
            </div>
            <h1>MUSESS</h1>
            <div class="row delay-content justify-content-center">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="text-center mt-3 mb-5">
                  <div class="divider"></div>
                  <p>
                    Musess is a collection of 999 portraits of women that
                    celebrate a borderless, inclusive and unveiled beauty.
                  </p>
                </div>
              </div>
            </div>
            <div v-if="account" class="delay-more-content">
              <h3>CLAIM YOUR FREE MUSESS</h3>
              <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-6">
                  <p class="mt-4">
                    Only addresses on the Free List can make the NFT claim.
                  </p>
                </div>
              </div>
              <div v-if="isWhitelisted" class="mt-5">
                <div class="mint-button" @click="mintClaim()">Claim Musess</div>
              </div>
              <div v-if="!isWhitelisted" class="mt-5">
                <h1>You're not in whitelist</h1>
              </div>
            </div>
            <div v-if="!account" class="delay-more-content">
              <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-6">
                  <p class="mt-4">Connect your wallet first</p>
                </div>
              </div>
              <div class="mt-5">
                <div class="mint-button" @click="connect()">Connect</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import { MerkleTree } from "merkletreejs";
import connectWallet from "@/mixins/connectWallet";
import axios from "axios";

const ABI = require("../ABI/abi.json");
const keccak256 = require("keccak256");

export default {
  name: "Claim",
  mixins: [connectWallet],
  components: {
    Header,
  },
  data() {
    return {
      step: 1,
      isWhitelisted: false,
      isMinted: false,
      networks: {
        ethereum: 1,
        rinkeby: 4,
        polygon: 137,
        mumbai: 80001,
        ganache: 5777,
        hardhat: 31337,
      },
      abi: ABI,
      account: "",
      network: "ethereum",
      contract: process.env.VUE_APP_CONTRACT_ADDRESS,
    };
  },
  mounted() {
    const app = this;
    this.connect();
    app.isWorking = true;
    app.workingMessage = "Checking if you're on whitelist or not..";
    const polling = setInterval(function () {
      app.checkWitelist();
      if(app.account.length > 0){
        clearInterval(polling)
      }
    }, 500);
  },
  methods: {
    async claim() {
      const app = this;
      const nftContract = new app.web3.eth.Contract(app.abi, app.contract);
      let proof = [];
      let errored = false;
      const storedRoot = await nftContract.methods.MERKLE_ROOT_FREE().call();
      let apiPrelist;
      try {
        const stored_presale = await axios.get(
          process.env.VUE_APP_API_PRESALE + "0"
        );
        this.storedlistPre = stored_presale.data;
        console.log("API_PRELIST", this.storedlistPre);
      } catch (e) {
        console.log(e);
        console.log("Can't process prelist");
      }
      const prelist = apiPrelist.data.split(",");
      const leaves = prelist.map((x) => keccak256(x));
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const root = tree.getRoot().toString("hex");
      if ("0x" + root === storedRoot) {
        proof = tree.getHexProof(keccak256(app.account));
        console.log("Calculated proof is: " + JSON.stringify(proof));
      } else {
        errored = true;
        console.log("Calculated is: " + root);
        console.log("Stored is: " + storedRoot);
        console.log(
          "Root doesn't matches, there's an error on stored whitelist, must recalculate"
        );
      }
      if (!errored) {
        try {
          const receipt = await nftContract.methods
            .claimNFT(proof)
            .send({ from: app.account })
            .on("transactionHash", (tx) => {
              console.log("Waiting for confirmation at: " + tx);
            });
          console.log(JSON.stringify(receipt));
          console.log("NFT claimed successfully!");
        } catch (e) {
          console.log(e.message);
          console.log(JSON.stringify(e));
        }
      }
    },
    async checkWitelist() {
      console.log("checking whitelist");
      const app = this;
      try {
        const stored_presale = await axios.get(
          process.env.VUE_APP_API_PRESALE + "0"
        );
        app.storedlistPre = stored_presale.data;
        console.log("API_PRELIST", app.storedlistPre);
        for (let k in app.storedlistPre) {
          if (
            app.storedlistPre[k].toUpperCase() === app.account.toUpperCase()
          ) {
            app.isWhitelisted = true;
            console.log("Found in freelist, price updated");
          }
        }
      } catch (e) {
        console.log(e);
        console.log("Can't process freelist");
      }
      app.total = app.price;
      app.$root.$emit("priceFetched", app.price);
    },
    async mintClaim() {
      const app = this;
      if (app.isSale !== undefined && app.isSale === true) {
        try {
          const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
          if (app.account !== undefined && app.account.length > 0) {
            app.isWorking = true;
            app.workingMessage = "Checking Freelist...";
            let proof = [];
            if (app.storedlistPre) {
              let leaves = await app.storedlistPre.map((x) => keccak256(x));
              const tree = new MerkleTree(leaves, keccak256, {
                sortPairs: true,
              });
              app.workingMessage = "Freelist is confirmed, creating proof...";
              proof = tree.getHexProof(keccak256(app.account));
            }
            console.log("Try to claim NFT");
            app.workingMessage = "Please confirm transaction on your wallet...";
            try {
              const receipt = await nftContract.methods
                .claimNFT(proof)
                .send({ from: app.account })
                .on("transactionHash", (tx) => {
                  app.workingMessage = "Waiting for confirmation at: " + tx;
                  app.pending = tx;
                });
              console.log(JSON.stringify(receipt));
              console.log("NFT claimed successfully!");
              app.isMinted = true;
              app.isWorking = true;
              app.workingMessage = "NFT claimed correctly";
              setTimeout(function () {
                app.isWorking = false;
                app.workingMessage = "";
              }, 4000);
            } catch (e) {
              console.log(e.message);
              console.log(JSON.stringify(e));
            }
          } else {
            app.isWorking = false;
            app.workingMessage = "";
            alert("Make sure that you have inserted address correctly");
          }
        } catch (e) {
          app.isWorking = false;
          app.workingMessage = "";
          console.log("Minting Failed", JSON.stringify(e.message));
          app.isMinted = false;
        }
      } else {
        alert("You can't mint right now, Retry later");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.coming-soon {
  position: relative;
  width: 100vw;
  height: 100vh;
}
h1,
h2,
h3,
p {
  margin: 0;
}

.delay-content {
  -webkit-animation: delay-content-animation 1s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: delay-content-animation 1s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation-delay: 1s;
  @-webkit-keyframes delay-content-animation {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes delay-content-animation {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
}

.delay-more-content {
  -webkit-animation: delay-more-content-animation 1s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: delay-more-content-animation 1s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation-delay: 1s;
  @-webkit-keyframes delay-more-content-animation {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes delay-more-content-animation {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
}

.content {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  h1 {
    font-family: "Bellota Text";
    font-size: 5rem;
    font-weight: bold;
    letter-spacing: 20px;
    line-height: 1;

    -webkit-animation: text-focus-in 3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
      both;
    animation: text-focus-in 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    @-webkit-keyframes text-focus-in {
      0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
      }
      100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
      }
    }
    @keyframes text-focus-in {
      0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
      }
      100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
      }
    }
  }
  h2 {
    font-family: "Satoshi-Regular";
    font-size: 2rem;
    line-height: 1;
  }
  h3 {
    font-family: "Bellota Text";
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 20px;
    line-height: 150%;
  }
  p {
    color: #ffffff;
    font-family: "Satoshi-Regular";
    font-size: 1.625rem;
    letter-spacing: 0.6px;
    line-height: 125%;
  }
}

.muse-img {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100vh;
  z-index: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
