<template>
  <div>
    <div class="d-flex flex-column p-5">
      <div class="d-flex justify-content-end justify-content-lg-between">
        <h5 class="ms-5 ps-5 hideMobile">
          THE ORIGINALS.<br />BEAUTY, <br />UNVEILED.
        </h5>
        <div
          v-if="randomOriginals.length !== undefined"
          class="muse-img-container"
        >
          <img :src="randomOriginals[0]" alt="" />
        </div>
      </div>
      <div
        v-if="randomOriginalsTWO.length !== undefined"
        class="muse-img-container align-self-start align-self-lg-center second-muse"
      >
        <img :src="randomOriginalsTWO[0]" alt="" />
      </div>
      <div
        v-if="randomOriginalsTHREE.length !== undefined"
        class="muse-img-container align-self-end third-muse"
      >
        <img :src="randomOriginalsTHREE[0]" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "loop-originals",
  data() {
    return {
      musess: [],
      musessTWO: [],
      musessTHREE: [],
      randomOriginals: [],
      randomOriginalsTWO: [],
      randomOriginalsTHREE: [],
    };
  },
  async mounted() {
    const app = this;
    const response = await axios.get(process.env.VUE_APP_API_LIST_ONE);
    const responseTwo = await axios.get(process.env.VUE_APP_API_LIST_TWO);
    const responseThree = await axios.get(process.env.VUE_APP_API_LIST_THREE);

    console.log("Response totale", response.data);
    let list = response.data.list;
    let nfts = response.data.nfts;

    let listTWO = responseTwo.data.list;
    let nftsTWO = responseTwo.data.nfts;

    let listTHREE = responseThree.data.list;
    let nftsTHREE = responseThree.data.nfts;

    for (let k of list) {
      app.musess.push(nfts[k].image);
    }
    for (let y of listTWO) {
      app.musessTWO.push(nftsTWO[y].image);
    }
    for (let z of listTHREE) {
      app.musessTHREE.push(nftsTHREE[z].image);
    }
    setTimeout(function () {
      app.getRandom();
    }, 10);

    setInterval(function () {
      app.getRandom();
    }, 5000);

    setTimeout(function () {
      app.getRandomTWO();
    }, 10);

    setInterval(function () {
      app.getRandomTWO();
    }, 5000);

    setTimeout(function () {
      app.getRandomTHREE();
    }, 10);

    setInterval(function () {
      app.getRandomTHREE();
    }, 5000);
    // const app = this;
    // const response = await axios.get(process.env.VUE_APP_API_LIST_ONE);
    // const responseTwo = await axios.get(process.env.VUE_APP_API_LIST_TWO);
    // const responseThree = await axios.get(process.env.VUE_APP_API_LIST_THREE);

    // let list = response.data.list;
    // let nfts = response.data.nfts;

    // let listTWO = responseTwo.data.list;
    // let nftsTWO = responseTwo.data.nfts;

    // let listTHREE = responseThree.data.list;
    // let nftsTHREE = responseThree.data.nfts;

    // setTimeout(function () {
    //   for (let k of list) {
    //     app.musess.push(nfts[k].image);
    //     const random = Math.floor(Math.random() * app.musess.length);
    //     app.randomMusess = app.musess[random];
    //   }
    // }, 10);

    // setTimeout(function () {
    //   for (let y of listTWO) {
    //     app.musessTWO.push(nftsTWO[y].image);
    //     let random = Math.floor(Math.random() * app.musessTWO.length);
    //     app.randomMusessTWO = app.musessTWO[random];
    //   }
    // }, 10);

    // setTimeout(function () {
    //   for (let z of listTHREE) {
    //     app.musessTHREE.push(nftsTHREE[z].image);
    //     let random = Math.floor(Math.random() * app.musessTHREE.length);
    //     app.randomMusessTHREE = app.musessTHREE[random];
    //   }
    // }, 10);

    // setInterval(function () {
    //   for (let k of list) {
    //     app.musess.push(nfts[k].image);
    //     let random = Math.floor(Math.random() * app.musess.length);
    //     app.randomMusess = app.musess[random];
    //   }
    // }, 5000);

    // setInterval(function () {
    //   for (let y of listTWO) {
    //     app.musessTWO.push(nftsTWO[y].image);
    //     let random = Math.floor(Math.random() * app.musessTWO.length);
    //     app.randomMusessTWO = app.musessTWO[random];
    //   }
    // }, 5000);

    // setInterval(function () {
    //   for (let z of listTHREE) {
    //     app.musessTHREE.push(nftsTHREE[z].image);
    //     let random = Math.floor(Math.random() * app.musessTHREE.length);
    //     app.randomMusessTHREE = app.musessTHREE[random];
    //   }
    // }, 5000);
  },
  methods: {
    getRandom() {
      const app = this;
      app.randomOriginals = [];
      while (app.randomOriginals.length <= 1) {
        let random = Math.floor(Math.random() * app.musess.length);
        if (app.randomOriginals.indexOf(app.musess[random]) === -1) {
          app.randomOriginals.push(app.musess[random]);
        }
      }
    },
    getRandomTWO() {
      const app = this;
      app.randomOriginalsTWO = [];
      while (app.randomOriginalsTWO.length <= 1) {
        let random = Math.floor(Math.random() * app.musessTWO.length);
        if (app.randomOriginalsTWO.indexOf(app.musessTWO[random]) === -1) {
          app.randomOriginalsTWO.push(app.musessTWO[random]);
        }
      }
    },
    getRandomTHREE() {
      const app = this;
      app.randomOriginalsTHREE = [];
      while (app.randomOriginalsTHREE.length <= 1) {
        let random = Math.floor(Math.random() * app.musessTHREE.length);
        if (app.randomOriginalsTHREE.indexOf(app.musessTHREE[random]) === -1) {
          app.randomOriginalsTHREE.push(app.musessTHREE[random]);
        }
      }
    },
  },
};
</script>
