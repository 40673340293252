<template>
  <div class="aimade-page">
    <Header />
    <section class="bg-color">
      <div class="scroll-down"></div>
      <div class="container p-5">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-9 text-start">
            <div class="p-5 mt-5 no-padding-x-mobile">
              <div class="d-flex align-items-center hideMobile">
                <a href="/">
                  <div class="go-back">
                    <img src="../assets/img/vec_back.svg" alt="" />
                  </div>
                </a>
                <h4 class="m-0 white">BACK</h4>
              </div>
              <h1 class="mt-4 mb-5">
                Impossible Beauty. <br />Reimagined by an AI.
              </h1>
              <p class="mb-5">
                899 stunning paintings without a painter. Each with a story
                dreamt by a machine.
              </p>
              <a href="/mint">
                <div v-if="todayIs30" class="colorfull">PRE-MINT MAY 30</div>
                <div v-if="!todayIs30" class="colorfull">MINT</div></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-color">
      <div class="container px-5">
        <div class="grid-ai-box" :class="{ 'px-5': !isMobile }">
          <div
            v-for="(item, index) in musess"
            :key="index"
            @click="showModal(item)"
            class="box-ai-made"
          >
            <img class="ai-img" :src="item.image" alt="" />
            <div class="hover-box">
              <div class="title-box">
                <h3>{{ item.name }}</h3>
              </div>
            </div>
          </div>
          <modal name="musa">
            <div v-if="selected.attributes !== undefined" class="container">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="text-start mb-4">
                  {{ selected.attributes[1].value }}
                </h1>
                <i
                  @click="closeModal()"
                  class="fa-solid fa-xmark hideDesktop"
                ></i>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 col-lg-5">
                  <div class="box-img-modal">
                    <img :src="selected.image" alt="" />
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-7 text-start">
                  <div
                    class="d-flex align-items-baseline justify-content-between"
                  >
                    <div class="d-flex align-items-baseline">
                      <div class="line"></div>
                      <h5 class="ms-1 mt-3">
                        {{ selected.attributes[0].value }}
                      </h5>
                    </div>
                    <i
                      @click="closeModal()"
                      class="fa-solid fa-xmark hideMobile"
                    ></i>
                  </div>
                  <div class="mt-3 mb-5">
                    <h2>{{ selected.name }}</h2>

                    <div class="mt-5">
                      <h3 class="mt-3">
                        {{ selected.description.split("\n\n")[0] }}
                      </h3>
                      <p class="mt-3">
                        {{ selected.description.split("\n\n")[1] }}
                      </p>
                      <div class="d-flex align-items-baseline">
                        <p>Written by:</p>
                        <p
                          class="ms-2"
                          style="font-weight: 900; font-size: 1.1rem"
                        >
                          {{ selected.attributes[4].value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <a href="/mint">
                    <div v-if="todayIs30" class="colorfull">
                      PRE-MINT MAY 30
                    </div>
                    <div v-if="!todayIs30" class="colorfull">MINT</div></a
                  >
                </div>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </section>
    <div class="gap bg-color"></div>
    <div class="gap bg-color"></div>
    <MarqueeText
      class="banner-container bg-color project-banner"
      :duration="30"
    >
      <span class="banner-text">
        AI-PAINTED MUSESS <b> AI-PAINTED MUSESS</b> AI-PAINTED MUSESS
        <b> AI-PAINTED MUSESS</b> AI-PAINTED MUSESS
      </span>
    </MarqueeText>
    <section class="originals" style="position: relative">
      <div id="musess" class="container mt-5 p-5">
        <div class="row justify-content-md-center">
          <div class="col-12 col-md-12 col-lg-6 text-start">
            <div class="p-5">
              <div class="d-flex align-items-baseline">
                <div class="line"></div>
                <h5 class="ms-1">THE ORIGINAL MUSESS</h5>
              </div>
              <h2 class="mt-4 mb-5">100 Original Musess</h2>
              <p class="mt-4 mb-5">
                The 100 Originals give you the right to claim the correspondent
                physical painting. Each physical portrait is hand-painted by Eva
                Adamian and counts as a separate NFT, with its own QR code and
                its physical story.
              </p>
              <a href="/originals-musess"
                ><div class="mint-button mt-5">VIEW MORE</div></a
              >
            </div>

            <div class="gap hideIpad"></div>
            <div class="gap hideIpad"></div>
            <div class="gap hideIpad"></div>
          </div>
          <div class="col-12 col-md-10 col-lg-6 text-start">
            <LoopOriginals />
          </div>
        </div>
        <div class="big-title originals-text">Originals</div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import checkDate from "@/mixins/checkDate";
import MarqueeText from "vue-marquee-text-component";
import LoopOriginals from "@/components/LoopOriginals.vue";
import { isMobile } from "mobile-device-detect";
import axios from "axios";

export default {
  name: "AiMade",
  mixins: [checkDate],
  components: {
    Header,
    Footer,
    MarqueeText,
    LoopOriginals,
  },
  data() {
    return {
      musess: [],
      selected: {},
      isMobile,
    };
  },
  async mounted() {
    const app = this;
    app.isDateToday();
    const response = await axios.get(process.env.VUE_APP_API_AI);
    console.log("Response totale", response.data);
    let list = response.data.list;
    let nfts = response.data.nfts;
    // for (let k in list) {
    //   app.musess.push(nfts[list[k]]);
    // }
    for (let k of list) {
      app.musess.push(nfts[k]);
    }
  },
  methods: {
    showModal(item) {
      this.selected = item;
      this.$modal.show("musa");
      console.log(item);
    },
    closeModal() {
      this.$modal.hide("musa");
    },
  },
};
</script>

<style lang="scss" scoped>
.originals * .aimade-text {
  position: absolute !important;
  left: -0.5%;
  bottom: -3%;
  margin-top: 0%;
}

// @media screen and (max-width: 767px) {
//   .originals * .originals-text {
//     margin-top: -12.5%;
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 1024px) {
//   .originals * .originals-text {
//     margin-top: -9.5%;
//   }
// }
</style>
