import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSmoothScroll from "vue2-smooth-scroll";
import vmodal from "vue-js-modal";
import VueLazyload from "vue-lazyload";

import "./themes/style.scss";
import "./themes/resp.scss";
import "./fonts/stylesheet.css";
import "animate.css";

Vue.config.productionTip = false;
Vue.use(VueSmoothScroll, {
  duration: 500,
  offset: -100,
});
Vue.use(vmodal);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "dist/error.png",
  loading: "dist/loading.gif",
  attempt: 1,
});

window.$ = require("jquery");

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
