<template>
  <div id="minting-page">
    <Header />
    <div class="container-workingMessage" v-if="isWorking">
      <div class="workingMessage">
        <i class="fas fa-spinner fa-pulse"></i>
        <h2 class="mt-3">{{ workingMessage }}...</h2>
      </div>
    </div>
    <section id="" class="bg-color">
      <div class="container px-5 pt-5">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6 text-start">
            <div class="p-5 mt-5 no-padding-x-mobile">
              <div class="d-flex align-items-baseline">
                <div class="line"></div>
                <h5 class="ms-1">MINTING</h5>
              </div>
              <div v-if="todayIs30">
                <h1 class="mt-4 mb-4">Musess pre-sale.</h1>
                <p>
                  Welcome to the presale minting for allowlist members only. You
                  can mint up to 20 Musess per wallet at a discounted price of
                  0.15 eth. Choose the quantity and click Mint Now to receive
                  your Musess.
                </p>
              </div>
              <div v-if="!todayIs30">
                <h1 class="mt-4 mb-4">Musess Minting.</h1>
                <p>
                  Welcome to the Musess Minting. If your wallet has been
                  allowlisted, you will be able to mint Musess at the discounted
                  price of 0.15 eth. If you're in the general public, the
                  minting price is 0.18 eth. You can mint up to 20 Musess.
                  Select the Musess quantity and click Mint Now to receive your
                  Musess.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="px-5 no-padding-x-mobile">
          <div class="container-minting pt-5 pb-5">
            <!-- MINTING SELECTION -->
            <div class="row" v-if="account && isSale && !isMinting">
              <h3 class="text-start m-0">HOW MANY DO YOU WANT TO MINT?</h3>
              <p class="text-start" :class="{ 'mb-5': !isWhitelisted }">
                You are connected with: {{ account }}
              </p>
              <h3 v-if="isWhitelisted" class="mb-5 text-start">
                You are whitelisted
              </h3>
              <div class="col-12 col-md-12 col-lg-5 text-start">
                <div class="select-amount">
                  <custom-slider min="1" max="20" step="1" v-model="amount" />
                  <div class="box-minmax"><span>1</span><span>20</span></div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-5 offset-lg-2 text-start">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <p class="small-text">Price</p>
                    <h3>{{ total }} ETH</h3>
                    <!-- <p class="small-text">$ {{ converted }}</p> -->
                  </div>
                  <div class="mint-button" @click="mint()">Mint Now</div>
                </div>
              </div>
            </div>
            <div class="text-center" v-if="account && !isSale">
              <h2 class="mb-2">Sorry, you can't mint right now.</h2>
            </div>
            <!-- END MINTING SELECTION -->
            <div v-if="!account" class="text-center">
              <h2 class="mb-2">You have to connect your wallet first</h2>
              <div class="mint-button mt-4" @click="connect()">Connect</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      class="d-flex justify-content-between px-2 bg-color"
      style="position: relative"
    >
      <div class="big-text">
        Mint <br />
        Process
      </div>
      <div class="box-img self-align-start">
        <img src="../assets/img/museminting.jpg" alt="" />
      </div>
    </div>
    <!-- BANNER -->
    <MarqueeText
      class="banner-container-inverted project-banner"
      :duration="30"
      :repeat="30"
    >
      <span class="banner-text"> MUSESS <b>MINT NOW</b>&nbsp; </span>
    </MarqueeText>
    <!-- END BANNER -->
    <Footer />
  </div>
</template>

<script>
// import Web3 from "web3";
// import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import checkViewport from "@/mixins/checkViewport";
import connectWallet from "@/mixins/connectWallet";
import checkDate from "@/mixins/checkDate";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MarqueeText from "vue-marquee-text-component";
import axios from "axios";
import CustomSlider from "vue-custom-range-slider";
// import the styling, css or scss
import "vue-custom-range-slider/dist/vue-custom-range-slider.css";

const keccak256 = require("keccak256");
const { MerkleTree } = require("merkletreejs");

export default {
  name: "MintingPage",
  mixins: [checkViewport, connectWallet, checkDate],
  data() {
    return {
      isMinting: false,
      isWhitelisted: false,
      converted: "",
      method: "mint",
      contract: process.env.VUE_APP_CONTRACT_ADDRESS,
      explorerUrl: "https://etherscan.io/tx/",
      parameters: "",
      total: 0,
      amount: "1",
      web3: "",
      pending: "",
      price: 0,
    };
  },
  components: {
    Header,
    Footer,
    MarqueeText,
    CustomSlider,
  },
  mounted() {
    // check account connected
    const app = this;
    app.isDateToday();
    if (localStorage.getItem("connected") !== null) {
      app.connect();
    }

    app.$root.$on("web3connected", (account) => {
      if (app.account.length === 0) {
        app.account = account;
        app.connect();
      } else {
        console.log("Checking whitelist..");
        app.checkWhitelist();
        if (app.isDebug === true) {
          console.log("Public sale status:", app.isSale);
        }
      }
    });
    // end check account
  },
  watch: {
    amount() {
      const app = this;
      if (parseInt(app.amount) > 20) {
        app.amount = "20";
      }
      if (parseInt(app.amount) <= 0) {
        app.amount = "1";
      }
      app.total = (app.price * parseInt(app.amount)).toFixed(3);
      this.returnChange();
    },
  },
  methods: {
    async checkWhitelist() {
      console.log("checking whitelist");
      const app = this;
      let price = 0.18;
      try {
        const stored_presale = await axios.get(
          process.env.VUE_APP_API_PRESALE + "1"
        );
        app.storedlistPre = stored_presale.data;
        // console.log("API_PRELIST", app.storedlistPre);
        if (
          app.storedlistPre.error === undefined ||
          (app.storedlistPre.error !== undefined &&
            app.storedlistPre.error === false)
        ) {
          for (let k in app.storedlistPre) {
            if (
              app.storedlistPre[k].toUpperCase() === app.account.toUpperCase()
            ) {
              price = 0.15;
              app.isWhitelisted = true;
              console.log("Found in whitelist, price updated");
            }
          }
        }
      } catch (e) {
        console.log(e);
        console.log("Can't process prelist");
      }
      app.price = price;
      app.total = app.price;
      app.$root.$emit("priceFetched", app.price);
    },
    async mint() {
      const app = this;
      if (app.isSale !== undefined && app.isSale === true) {
        try {
          const nftContract = new app.web3.eth.Contract(app.ABI, app.contract, {
            gasLimit: "700000",
          });
          if (app.account !== undefined && app.account.length > 0) {
            app.isWorking = true;
            app.workingMessage = "Checking Whitelist...";

            let proof = [];
            if (app.isWhitelisted) {
              let leaves = await app.storedlistPre.map((x) => keccak256(x));
              const tree = new MerkleTree(leaves, keccak256, {
                sortPairs: true,
              });
              app.workingMessage = "Whitelist is confirmed, creating proof...";
              proof = tree.getHexProof(keccak256(app.account));
            }
            const total = (app.amount * app.price).toFixed(2);
            const wei = app.web3.utils.toWei(total, "ether");
            const gasLimit = 100000 + app.amount * 125000;
            console.log("Try to buy NFT");
            app.workingMessage = "Please confirm transaction on your wallet...";
            const receipt = await nftContract.methods
              .buyNFT(proof)
              .send({
                from: app.account,
                value: wei.toString(),
                gas: gasLimit,
              })
              .on("transactionHash", (pending) => {
                app.workingMessage = "Waiting for confirmation at: " + pending;
                app.pending = pending;
              });
            app.isMinted = true;
            app.isWorking = true;
            app.workingMessage = "NFT Minted correctly";
            setTimeout(function () {
              app.isWorking = false;
              app.workingMessage = "";
            }, 4000);
            console.log("NFT minted correctly", JSON.stringify(receipt));
          } else {
            app.isWorking = false;
            app.workingMessage = "";
            alert("Make sure that you have inserted address correctly");
          }
        } catch (e) {
          app.isWorking = false;
          app.workingMessage = "";
          console.log("Minting Failed", JSON.stringify(e.message));
          app.isMinted = false;
        }
      } else {
        alert("You can't mint right now, Retry later");
      }
    },
    async returnChange(currency = "usd") {
      const app = this;
      if (app.isDebug) {
        console.log("Asking price from CoinGecko..");
      }
      let ethRates = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=" +
          currency
      );
      let ethRate = parseFloat(ethRates.data.ethereum[currency].toFixed(2));
      app.converted = (app.total * ethRate).toFixed(2);
    },
  },
};
</script>
