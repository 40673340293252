<template>
  <div v-if="randomMusess.length === 16" class="row px-5">
    <div class="col px-2">
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[0] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[1] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[2] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[3] + ')'"
      ></div>
    </div>
    <div class="col px-2 pt-5">
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[4] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[5] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[6] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[7] + ')'"
      ></div>
    </div>
    <div class="col px-2">
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[8] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[9] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[10] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[11] + ')'"
      ></div>
    </div>
    <div class="col px-2 pt-5">
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[12] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[13] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[14] + ')'"
      ></div>
      <div
        class="pic-ai-container align-self-start"
        :style="'background-image: url(' + randomMusess[15] + ')'"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "loop-aimade",
  data() {
    return {
      musess: [],
      randomMusess: [],
    };
  },
  async mounted() {
    const app = this;
    const response = await axios.get(process.env.VUE_APP_API_AI);
    console.log("Response totale", response.data);
    let list = response.data.list;
    let filtered = list.filter(function (value) {
      return value > 100;
    });
    console.log(filtered);
    let nfts = response.data.nfts;
    for (let k of filtered) {
      app.musess.push(nfts[k].image);
    }
    setTimeout(function () {
      app.getRandom();
    }, 10);

    setInterval(function () {
      app.getRandom();
    }, 5000);
  },
  methods: {
    getRandom() {
      const app = this;
      app.randomMusess = [];
      while (app.randomMusess.length <= 15) {
        let random = Math.floor(Math.random() * app.musess.length);
        if (app.randomMusess.indexOf(app.musess[random]) === -1) {
          app.randomMusess.push(app.musess[random]);
        }
      }
    },
  },
};
</script>
