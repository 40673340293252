<template>
  <!-- NAV -->
  <div class="header" id="navHead">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a href="/"> <div class="navbar-brand">MUSESS</div></a>
        <div
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div class="navbar-toggler-icon" @click="forceshow()">
            <i class="fa-solid fa-bars main-color"></i>
          </div>
        </div>
        <div
          class="collapse navbar-collapse"
          :class="{ 'justify-content-center': !changeNavigation }"
          id="navbarSupportedContent"
        >
          <ul id="listLink" class="navbar-nav mb-2 mb-lg-0" role="tablist">
            <li v-if="!changeNavigation" class="nav-item">
              <a
                id="nfts-btn"
                class="nav-link me-3"
                href="/#project"
                @click="closeMenu()"
              >
                The Project</a
              >
            </li>
            <li v-if="!changeNavigation" class="nav-item">
              <a class="nav-link me-3" href="/#paintress" @click="closeMenu()"
                >The Paintress</a
              >
            </li>
            <li v-if="!changeNavigation" class="nav-item">
              <a class="nav-link me-3" href="/#originals" @click="closeMenu()"
                >The Musess</a
              >
            </li>
            <li v-if="!changeNavigation" class="nav-item">
              <a class="nav-link" href="/#scene" @click="closeMenu()"
                >Behind the Scene</a
              >
            </li>
            <li v-if="changeNavigation" class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                @click="showDropdown = !showDropdown"
                style="cursor: pointer; font-size: 1.5rem !important"
              >
                Navigate
              </a>
              <ul
                v-if="showDropdown"
                class="dropdown-menu"
                style="font-size: 1.5rem !important"
              >
                <li>
                  <a
                    class="dropdown-item"
                    href="/#project"
                    @click="showDropdown = !showDropdown"
                    >The Project</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="/#paintress"
                    @click="showDropdown = !showDropdown"
                    >The Paintress</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="/#originals"
                    @click="showDropdown = !showDropdown"
                    >Originals</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="/#scene"
                    @click="showDropdown = !showDropdown"
                    >Behind the Scene</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="btn-menu" class="btn-menu-container">
          <div
            v-if="isCollapseHeader && todayIs30"
            class="mint-button my-5"
            @click="closeMenu()"
          >
            PRE-MINT MAY 30
          </div>
          <div
            v-if="isCollapseHeader && !todayIs30"
            class="mint-button my-5"
            @click="closeMenu()"
          >
            MINT
          </div>
          <div
            class="d-flex flex-column flex-md-row flex-lg-row flex-start align-items-center"
          >
            <a href="/mint">
              <div
                v-if="!isCollapseHeader && todayIs30"
                class="btn btn-menu ms-3"
                @click="closeMenu()"
              >
                PRE-MINT MAY 30
              </div>
              <div
                v-if="!isCollapseHeader && !todayIs30"
                class="btn btn-menu ms-3"
                @click="closeMenu()"
              >
                MINT
              </div>
            </a>
            <div id="connect-wallet">
              <div v-if="!thisConnectedAccount">
                <div class="btn btn-menu ms-3" @click="connect(), setAccount()">
                  CONNECT
                </div>
              </div>
              <div v-if="thisConnectedAccount">
                <a href="/my-profile">
                  <div class="btn btn-menu ms-3 connectButton">
                    <span>
                      {{
                        thisConnectedAccount.substr(0, 4) +
                        "..." +
                        thisConnectedAccount.substr(-4)
                      }}</span
                    >
                  </div></a
                >
                <div @click="disconnect()" class="btn btn-menu ms-3">
                  <span> DISCONNECT</span>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <a
                class="social-icon"
                href="https://twitter.com/Musess_art"
                target="_blank"
                ><i class="fa-brands fa-twitter"></i
              ></a>
              <a
                class="social-icon"
                href="https://www.instagram.com/musess_art/"
                target="_blank"
                ><i class="fa-brands fa-instagram"></i
              ></a>
              <a
                class="social-icon"
                href="https://discord.com/invite/musess"
                target="_blank"
                ><i class="fa-brands fa-discord"></i
              ></a>
              <a
                class="social-icon-spatial spatial-dark"
                href="https://spatial.io/s/Musess-the-Impossible-Exhibition-6256acb21113e700013e80de?share=8505081060972342593"
                target="_blank"
              ></a>
              <a
                class="social-icon-opensea opensea-dark"
                href="https://opensea.io/collection/musessart"
                target="_blank"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import checkDate from "@/mixins/checkDate";
import ConnectWallet from "@/mixins/connectWallet";

export default {
  name: "Header",
  mixins: [checkDate, ConnectWallet],

  data() {
    return {
      isHome: false,
      isCollapseHeader: false,
      showDropdown: false,
      changeNavigation: false,
      thisConnectedAccount: localStorage.getItem("account-connected"),
    };
  },
  mounted() {
    const app = this;
    if (
      app.account.length > 0 &&
      app.account !== localStorage.getItem("account-connected")
    ) {
      localStorage.setItem("account-connected", app.account);
      let thisConnectedAccount = localStorage.getItem("account-connected");
      app.thisConnectedAccount = thisConnectedAccount;
      console.log("connection at mounted is", app.thisConnectedAccount);
    }
    app.show();
    app.isDateToday();
    if (window.innerWidth < 992) {
      app.collapse();
      app.isCollapseHeader = true;
    } else {
      app.isCollapseHeader = false;
    }
    if (window.innerWidth > 992 && window.innerWidth < 1400) {
      app.changeNavigation = true;
    } else {
      app.changeNavigation = false;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 992) {
        app.show();
        app.isCollapseHeader = false;
      } else {
        app.isCollapseHeader = true;
      }
      if (window.innerWidth > 992 && window.innerWidth < 1400) {
        app.changeNavigation = true;
      } else {
        app.changeNavigation = false;
      }
    });
    window.addEventListener("resize", function () {
      if (window.innerWidth < 992) {
        app.collapse();
      }
    });
    this.activeLink();
    if (app.$route.name === "Home") {
      app.isHome = true;
    }
  },
  methods: {
    forceshow() {
      var menuBtn = document.getElementById("btn-menu");
      var navExpand = document.getElementById("navbarSupportedContent");
      if (window.innerWidth < 1300) {
        menuBtn.classList.toggle("hide");
        menuBtn.classList.toggle("forceshow");
        navExpand.classList.toggle("forceshow");
      }
    },
    show() {
      var menuBtn = document.getElementById("btn-menu");
      menuBtn.classList.remove("hide");
      menuBtn.classList.add("show");
    },
    collapse() {
      var menuBtn = document.getElementById("btn-menu");
      menuBtn.classList.remove("show");
      menuBtn.classList.add("hide");
    },
    closeMenu() {
      var menu = document.getElementById("navbarSupportedContent");
      var menuBtn = document.getElementById("btn-menu");
      if (menu.classList.contains("forceshow")) {
        menu.classList.remove("show");
        menu.classList.remove("forceshow");
        menuBtn.classList.remove("show");
        menuBtn.classList.add("hide");
      }
    },
    activeLink() {
      window.$(document).ready(function () {
        window.$(".navbar-nav a").click(function () {
          window.$(".navbar-nav a").removeClass("active");
          window.$(this).addClass("active");
        });
      });
    },

    setAccount() {
      const app = this;
      setTimeout(() => {
        if (
          localStorage.getItem("connected", true) &&
          localStorage.getItem("account-connected") !== null
        ) {
          let thisConnectedAccount = localStorage.getItem("account-connected");
          app.thisConnectedAccount = thisConnectedAccount;
          console.log("Im connected with:", app.thisConnectedAccount);
        }
      }, 2000);
    },
    disconnect() {
      localStorage.clear();
      window.location.reload();
    },
  },
};
</script>
<style lanc="scss">
.web3modal-modal-lightbox {
  position: relative;
  z-index: 9999 !important;
  width: 100vw !important;
}
.web3modal-modal-container {
  background-color: rgba(255, 255, 255, 0.6);
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
