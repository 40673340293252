import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Originals from "../views/Originals.vue";
import AiMade from "../views/AiMade.vue";
import ComingSoon from "../views/ComingSoon.vue";
import Newsletter from "../views/Newsletter.vue";
import MintingPage from "../views/MintingPage.vue";
import Profile from "../views/Profile.vue";
import Claim from "../views/Claim.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/mint",
    name: "MintingPage",
    component: MintingPage,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/originals-musess",
    name: "Originals",
    component: Originals,
  },
  {
    path: "/ai-made",
    name: "AiMade",
    component: AiMade,
  },
  {
    path: "/my-profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/coming-soon",
    name: "AiMade",
    component: ComingSoon,
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: Newsletter,
  },
  {
    path: "/claim",
    name: "Claim",
    component: Claim,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
